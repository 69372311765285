import { FileInfo, IdEither } from '@cognite/sdk';
import { PNID_DATASET_ID, PNID_MIME_TYPE, PNID_SOURCE } from 'config/apiConfig';
import { getClient } from '../config/cognitesdk';

export class FilesResourceService {
  fetchFilesByIds(ids: number[]): Promise<FileInfo[]> {
    const filesToGet: IdEither[] = ids.map((id) => {
      return { id };
    });
    return getClient().files.retrieve(filesToGet);
  }

  fetchFilesByName(fileName: string): Promise<FileInfo[]> {
    return getClient()
      .files.list({
        filter: {
          mimeType: PNID_MIME_TYPE,
          source: PNID_SOURCE,
          name: fileName,
          dataSetIds: [{ externalId: PNID_DATASET_ID }],
          metadata: { contextualized: 'T' },
        },
        limit: 1000,
      })
      .autoPagingToArray({ limit: -1 });
  }

  fetchFileById(id: number): Promise<FileInfo> {
    return this.fetchFilesByIds([id]).then((files) => files[0]);
  }

  searchFilesByAssetIds(assetIds: number[]): Promise<FileInfo[]> {
    return getClient().files.search({
      filter: {
        mimeType: PNID_MIME_TYPE,
        source: PNID_SOURCE,
        dataSetIds: [{ externalId: PNID_DATASET_ID }],
        assetIds,
        metadata: { contextualized: 'T' },
      },
    });
  }

  searchFilesByName(name: string): Promise<FileInfo[]> {
    return getClient().files.search({
      filter: {
        mimeType: PNID_MIME_TYPE,
        dataSetIds: [{ externalId: PNID_DATASET_ID }],
        source: PNID_SOURCE,
        metadata: { contextualized: 'T' },
      },
      search: { name },
      limit: 15,
    });
  }

  listPnIDsforAsset(assetId: number): Promise<FileInfo[]> {
    return getClient()
      .files.list({
        filter: {
          mimeType: PNID_MIME_TYPE,
          source: PNID_SOURCE,
          assetIds: [assetId],
          dataSetIds: [{ externalId: PNID_DATASET_ID }],
          metadata: { contextualized: 'T' },
        },
        limit: 1000,
      })
      .autoPagingToArray({ limit: -1 });
  }

  getFileDownloadUrl(fileId: number) {
    return getClient().files.getDownloadUrls([{ id: fileId }]);
  }

  checkForDuplicatedPnids(array: FileInfo[]) {
    const highestRevision = Math.max(
      ...array.map((item) =>
        Number(item.metadata?.revision || item.metadata?.REVISION)
      )
    );
    const highestRevisionPnids = array.filter(
      (item) =>
        Number(item.metadata?.REVISION || item.metadata?.revision) ===
        highestRevision
    );

    return highestRevisionPnids.length > 1;
  }

  getIdOfLatestFile(fileName: string): Promise<number> {
    return this.fetchFilesByName(fileName).then((filesResponse) => {
      if (!filesResponse || !filesResponse.length) {
        return 0;
      }

      if (this.checkForDuplicatedPnids(filesResponse)) {
        return 0;
      }

      const sortedResponse = filesResponse.sort(
        // if revision is different, use that, if same, use created date
        (a, b) => {
          const revisionA = a.metadata?.REVISION || a.metadata?.revision || 0;

          const revisionB = b.metadata?.REVISION || b.metadata?.revision || 0;
          return (
            Number(revisionB) - Number(revisionA) ||
            +b.createdTime - +a.createdTime
          );
        }
      );
      return sortedResponse[0].id;
    });
  }
}
